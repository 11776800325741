'use client';

import { UserProvider } from '@auth0/nextjs-auth0/client';
import { createContext, useMemo, useState } from 'react';
import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

config.autoAddCss = false;

export const ShapeContext = createContext(null);
export const GuildContext = createContext(null);
export const DiscordUserContext = createContext(null);
export const ShapeUserContext = createContext(null);

export function Providers({ children }) {
    const [discordUser, setDiscordUser] = useState(null);
    const [shapeUser, setShapeUser] = useState(null);
    const [shapes, setShapes] = useState(null);
    const [selectedShape, setSelectedShape] = useState(null);
    const [guilds, setGuilds] = useState({});

    const shape = useMemo(
        () => (shapes ? shapes[selectedShape] : null),
        [shapes, JSON.stringify(shapes), selectedShape]
    );

    return (
        <UserProvider>
            <DiscordUserContext.Provider
                value={{ discordUser, setDiscordUser }}
            >
                <ShapeUserContext.Provider value={{ shapeUser, setShapeUser }}>
                    <ShapeContext.Provider
                        value={{
                            shape,
                            shapes,
                            setShapes,
                            selectedShape, // jolteon
                            setSelectedShape,
                        }}
                    >
                        <GuildContext.Provider value={{ guilds, setGuilds }}>
                            {children}
                        </GuildContext.Provider>
                    </ShapeContext.Provider>
                </ShapeUserContext.Provider>
            </DiscordUserContext.Provider>
        </UserProvider>
    );
}
