'use client';

import { useEffect } from 'react';
import { Crisp } from 'crisp-sdk-web';

function CrispChat(props) {
    useEffect(() => {
        Crisp.configure('c2c4d706-9087-40cb-84d1-645037798ec1');
        // Set safe true
        Crisp.setSafeMode(true);
    }, []);

    return null;
}

export default CrispChat;
