'use client';

import styles from './snow.module.scss';

function Snow() {
    return (
        <div className={styles.snowContainer}>
            {[...Array(75)].map((_, i) => (
                <div key={i} className={styles.snowflake} />
            ))}
        </div>
    );
}

export default Snow;