'use client';

import { useEffect } from 'react';

function SupressWarnings(props) {
    useEffect(() => {
        // Keep a reference to the original console.error
        const originalConsoleError = console.error;

        // Override the console.error method
        console.error = function (...args) {
            // Check if the first argument (or any other relevant part of the message)
            // matches the pattern you expect from the specific module
            if (
                args.some(
                    (arg) =>
                        typeof arg === 'string' &&
                        arg.includes('Support for defaultProps')
                )
            ) {
                // If it matches, do nothing (ignore the message)
                return;
            }

            // Otherwise, call the original console.error method
            originalConsoleError.apply(console, args);
        };
    }, []);
    return null;
}

export default SupressWarnings;
